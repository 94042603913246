.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vh;
    background-color: #2c3e50;
}

.content-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.upper-part {
    flex: 1;
}

main {
    flex: 1;
    padding: 20px; /* Padding for content inside main */
}

footer {
    text-align: center;
    padding: 10px 0;
    color: white;
}
